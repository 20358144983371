<template>
    <div>
        <v-autocomplete
                v-model="model"
                color="grey lighten-1"
                :items="entries"
                :loading="isLoading"
                :search-input.sync="search"
                :dense="dense"
                :filled="filled"
                :chips="chips"
                :small-chips="chips"
                :multiple="multiple"
                :disabled="disabled"
                :required="required"
                no-data-text="Не знайдено жодного лічильника"
                :hide-details="true"
                :label="label"
                placeholder="почніть вводити текст для пошуку"
                @change="onAutocompleteChange"
                :solo="solo"
                :style="style_"
                :menu-props="menuProps"
                :clearable="clearable"
                :rules="rules()"
                :class="class_"
                @click="onSelectClick"
                return-object
        >
            <template v-slot:selection="data" v-if="chips">
                <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove(data.item)"
                        class="my-1"
                        color="white"
                >
                    <v-icon color="secondary" class="mr-3">
                        mdi-bank-outline
                    </v-icon>
                    <span class="grey--text text--darken-3">{{ data.item.text }}</span>
                </v-chip>
            </template>
            <template v-slot:item="data">
                <template>
                    <v-list-item-content style="padding: 6px 12px !important;" class="custom-list-item">
                        <v-list-item-subtitle v-html="data.item.text"
                                              :class="{'error--text font-weight-bold': data.item.counter_not_actual,
                                           'grey--text text--darken-2': !data.item.counter_not_actual}"
                        />
                    </v-list-item-content>
                </template>
            </template>
            <template v-slot:no-data>
                <v-list-item class="pa-0 align-center">
                  <v-list-item-icon class="mr-5 ml-3 align-self-center">
                    <v-icon size="39" color="error lighten-1">
                      mdi-emoticon-sad-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="font-weight-medium px-3">
                    <v-list-item-title style="font-size: .91rem">
                      Лічильники відсутні...
                    </v-list-item-title>
                    <v-list-item-subtitle class="pt-1">
                      спробуйте іншого абонента
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
import SelectAPI from '@/utils/axios/autocomplite'
// import {ALERT_SHOW} from "@/store/actions/alert";

export default {
    name: "FlatCounter",
    props: {
        filled: {
            type: Boolean,
            default: true
        },
        autosync: {
            type: Boolean,
            default: true
        },
        multiple: {
            type: Boolean,
            default: false
        },
        flat_id: {
            type: Number,
            default: 0
        },
        chips: {
            type: Boolean,
            default: false
        },
        value: {
            type: Number,
            default: null
        },
        text_value: {
            type: String,
            default: ''
        },
        service_id: {
            type: Number,
            default: null
        },
        start_reading: {
            type: Number,
            default: null
        },
        start_reading_second: {
            type: Number,
            default: null
        },
        start_reading_third: {
            type: Number,
            default: null
        },
        zone: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        counter_not_actual: {
            type: Boolean,
            default: false
        },
        solo: {
            type: Boolean,
            default: false
        },
        style_: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: 'Банк'
        },
        clearable: {
            type: Boolean,
            default: true
        },
        dense: {
            type: Boolean,
            default: true
        },
        denseList: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        row_num: {
            type: Number,
            default: 0
        },
        class_: {
            type: String,
            default: ''
        },
        clear: {
            type: Boolean,
            default: false
        },
        fetch_first: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        menuProps() {
            return {
                closeOnContentClick: this.modal,
                color: 'error'
            }
        }
    },
    data: () => ({
        entries: [],
        isLoading: false,
        fetched: false,
        model: null,
        search: null,
        flat_id_: null
    }),
    methods: {
        setOrFetchValue(payload) {
            if (payload) {
              if (this.text_value) {
                this.entries.push(
                    {
                      text: this.text_value,
                      value: payload,
                      current_meter_reading_first: this.start_reading,
                      current_meter_reading_second: this.start_reading_second,
                      current_meter_reading_third: this.start_reading_third,
                      zone: this.zone,
                      counter_not_actual: this.counter_not_actual
                    }
                )
                this.model = {
                  text: this.text_value,
                  value: payload,
                  current_meter_reading_first: this.start_reading,
                  current_meter_reading_second: this.start_reading_second,
                  current_meter_reading_third: this.start_reading_third,
                  zone: this.zone,
                  counter_not_actual: this.counter_not_actual
                }
              } else {
                this.fetch()
              }

            } else {
                if (this.clear) {
                    this.model = null
                }
                if (this.flat_id) {
                    this.fetch()
                    this.fetched = true
                    this.model = null
                } else {
                    this.entries = []
                    this.model = null
                }
            }
        },
        onSelectClick() {
            if (!this.entries.length) {
                if (this.flat_id) {
                    this.fetch()
                    this.fetched = true
                } else {
                    this.entries = []
                    this.model = null
                }
            }

            if (!this.fetched) {
                this.fetch(true)
                this.fetched = true
            }
        },
        rules() {
            if (this.required) {
                return [v => !!v || 'Це поле є обов’язковим']
            } else {
                return [true]
            }
        },
        fetch(not_emit = false) {
          if (!this.flat_id || !this.service_id) return
            SelectAPI.flat_counter_select(
                {flat_id: this.flat_id, service_id: this.service_id}
            )
                .then(response => response.data)
                .then(data => {
                    this.entries = data.map(item => {
                        return {
                            text: `№${item.serial_number}${item.is_legacy ? ', ' : ''}${item.is_legacy ? item.contract_name : ''}`,
                            value: item.id,
                            current_meter_reading_first: item.current_meter_reading_first,
                            current_meter_reading_second: item.current_meter_reading_second,
                            current_meter_reading_third: item.current_meter_reading_third,
                            zone: item.zone,
                            counter_not_actual: item.counter_not_actual
                        }
                    })

                    if (this.value && !this.text_value) {
                      const row = this.entries.find(i => i.value === this.value)
                      if (row) {
                        this.model = Object.assign(
                            {},
                            {
                              text: row.text,
                              value: row.value,
                              current_meter_reading_first: row.current_meter_reading_first,
                              current_meter_reading_second: row.current_meter_reading_second,
                              current_meter_reading_third: row.current_meter_reading_third,
                              zone: row.zone,
                              counter_not_actual: row.counter_not_actual
                            }
                        )
                      }
                    }

                    if (!not_emit) {
                        if (this.entries.length === 1) {
                            if (this.fetch_first) {
                                const row = this.entries[0]
                                this.model = Object.assign(
                                    {},
                                    {
                                        text: row.text,
                                        value: row.value,
                                        current_meter_reading_first: row.current_meter_reading_first,
                                        current_meter_reading_second: row.current_meter_reading_second,
                                        current_meter_reading_third: row.current_meter_reading_third,
                                        zone: row.zone,
                                        counter_not_actual: row.counter_not_actual
                                    }
                                )
                                this.onAutocompleteChange()
                            }
                        }
                    }
                })
        },
        onAutocompleteChange() {
            this.$emit('autocompleteChange', this.model ? Object.assign(this.model, {row_num: this.row_num}) : {
                value: null,
                row_num: this.row_num
            })
        },
        remove(item) {
            const fElement = this.model.find(i => i.value === item.value)
            const index = this.model.indexOf(fElement)
            if (index >= 0) this.model.splice(index, 1)
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(payload) {
                this.setOrFetchValue(payload)
            }
        },
        flat_id: {
            handler(payload) {
                if (payload) {
                    if (this.flat_id_ === null) {
                        this.flat_id_ = payload
                        this.setOrFetchValue(this.value)
                    } else {
                        if (payload !== this.flat_id_) {
                            this.setOrFetchValue(this.value)
                            this.flat_id_ = payload
                        }
                    }
                } else {
                    this.entries = []
                    this.model = null
                }
            }
        }
    },
}
</script>

<style scoped lang="scss">
.main-height:deep(.v-input__control) {
  min-height: 34px !important;
}

.main-height:deep(.v-input__control .v-input__slot) {
  min-height: 34px !important;
}

.close {
  position: absolute;
  right: -8px;
  top: -9px;
  z-index: 1;
}

.table-input {
  width: 84%;
  display: block;
  outline: none;

  //&:focus {
  //  border-color: var(--v-success-base);
  //  font-weight: 500;
  //}
  &:deep(input) {
    font-size: .84rem !important;
    padding: 3px 4px 2px 0 !important;
    transition: all .2s ease-in-out !important;
    line-height: 0 !important;
  }

  &:deep(.v-input) {
    margin-top: 0 !important;

    .v-input__slot:before {
      border: none !important;
    }

    .v-input__slot:after {
      border: none !important;
    }

    .v-input__append-inner {
      display: none !important;
    }
  }
}

.custom-append-list {
  display: flex;
  padding: 0;
  text-transform: uppercase;

  a {
    display: block;
    width: 50%;
    text-align: center;
    padding: 8px;
    font-weight: 500;
    font-size: 0.9rem;

    &:nth-child(1) {
      background-color: #585858;
      color: white;
      text-decoration: none;
    }

    &:nth-child(2) {
      background-color: #f18a0d;
      color: white;
      text-decoration: none;
    }
  }
}

//div:has(.custom-list-item) {
//  background-color: #d17000;
//}
</style>